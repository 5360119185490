import { CompProps, Context, IContext, IPropsStore, Props } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'

type PlatformOnPropsChangedHandler = (overrideProps: CompProps) => void
export const controlledComponentFactory = withDependencies(
	[Props, Context],
	(propsStore: IPropsStore, context: IContext) => {
		let platformOnPropsChangedHandler: PlatformOnPropsChangedHandler
		return {
			appWillMount() {
				context.extend({
					updateControlledComponentProps: (overrideProps: any) => {
						propsStore.update(overrideProps)
						if (platformOnPropsChangedHandler) {
							platformOnPropsChangedHandler(overrideProps)
						}
					},
				})
			},
			getSdkHandlers() {
				return {
					registerOnPropsChangedHandler(handler: PlatformOnPropsChangedHandler) {
						platformOnPropsChangedHandler = handler
					},
				}
			},
		}
	}
)
