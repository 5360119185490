import { SiteAssetsRequest } from 'site-assets-client'
import { TBSiteAssetsRequest } from './types'
import { stringifyExperiments } from './utils'
import {
	CssSiteAssetsParams,
	ModulesToHashes,
	PlatformSiteAssetsParams,
	PropsSiteAssetsParams,
	SiteAssetsResourceType,
	StructureSiteAssetsParams,
	ViewerModel,
} from '@wix/thunderbolt-symbols'

type OneOfSiteAssetsParams =
	| CssSiteAssetsParams
	| PlatformSiteAssetsParams
	| PropsSiteAssetsParams
	| StructureSiteAssetsParams

type SiteAssetsParamsMap<U> = { [K in SiteAssetsResourceType]: U extends { resourceType: K } ? U : never }
type SiteAssetsParamsTypeMap = SiteAssetsParamsMap<OneOfSiteAssetsParams>
type Pattern<T> = { [K in keyof SiteAssetsParamsTypeMap]: (params: SiteAssetsParamsTypeMap[K]) => T }
function matcher<T>(pattern: Pattern<T>): (params: OneOfSiteAssetsParams) => T {
	// https://github.com/Microsoft/TypeScript/issues/14107
	return (params) => pattern[params.resourceType](params as any)
}

export const getUniqueParamsPerModule = ({
	deviceInfo,
	staticHTMLComponentUrl,
	qaMode,
}: {
	deviceInfo: ViewerModel['deviceInfo']
	staticHTMLComponentUrl: string
	qaMode?: ViewerModel['mode']['qa']
}) => {
	return matcher<Record<string, string>>({
		css: (cssParams) => {
			return {
				language: cssParams.language,
				stylableMetaData: cssParams.stylableMetaData,
			}
		},
		props: (propsParams) => {
			return {
				language: propsParams.language,
				useSandboxInHTMLComp: `${propsParams.useSandboxInHTMLComp}`,
				deviceType: deviceInfo.deviceClass,
				osType: deviceInfo.os,
				staticHTMLComponentUrl,
			}
		},
		structure: () => {
			return {
				...(qaMode && { qaMode }),
			}
		},
		platform: (platformParams) => {
			return {
				language: platformParams.language,
			}
		},
	})
}

export function toSiteAssetsRequest(request: TBSiteAssetsRequest, modulesToHashes: ModulesToHashes) {
	const {
		moduleParams,
		siteScopeParams: { freemiumBanner, viewMode, isWixSite, isResponsive, wixCodePageIds },
		pageCompId,
		errorPageId,
		beckyExperiments,
		deviceInfo,
		staticHTMLComponentUrl,
		qaMode,
	} = request
	const { contentType, moduleName } = moduleParams
	const errorPageIdStr = errorPageId ? errorPageId : ''

	const siteAssetsRequest: SiteAssetsRequest = {
		endpoint: {
			controller: 'pages',
			methodName: 'thunderbolt',
		},
		module: {
			name: moduleName,
			version: modulesToHashes[moduleName],
			fetchType: 'file',
			params: {
				freemiumBanner: `${freemiumBanner}`,
				isWixCodeOnPage: `${wixCodePageIds.includes(pageCompId)}`,
				viewMode,
				isWixSite: `${isWixSite}`,
				errorPageId: errorPageIdStr,
				isResponsive: `${isResponsive}`,
				beckyExperiments: stringifyExperiments(beckyExperiments),
				...getUniqueParamsPerModule({
					deviceInfo,
					staticHTMLComponentUrl,
					qaMode,
				})(moduleParams),
			},
		},
		pageId: pageCompId,
		contentType,
		fallbackStrategy: 'disable',
	}
	return siteAssetsRequest
}
