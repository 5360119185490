// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../../node_modules/@wix/fedops-logger/types.d.ts"/>
import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import {
	LoggerSymbol,
	ILogger,
	Interaction,
	Phase,
	LoggerIntegrations,
	ViewerModel,
	FedopsStore,
} from '@wix/thunderbolt-symbols'
import { Environment } from '../../types/Environment'

const getBiStore = (viewerSessionId: string, viewerModel: ViewerModel): FedopsStore => {
	const {
		site: { metaSiteId, sessionId, dc },
		rollout: { isDACRollout, siteAssetsVersionsRollout },
		fleetConfig: { code },
	} = viewerModel
	return {
		msid: metaSiteId,
		sessionId,
		viewerSessionId,
		requestId: viewerModel.requestId,
		dc,
		is_rollout: code === 0 || code === 1 ? !!code : null,
		is_dac_rollout: isDACRollout ? 1 : 0,
		is_sav_rollout: siteAssetsVersionsRollout ? 1 : 0,
		isCached: process.env.browser ? window.fedops.is_cached : false,
		is_headless: false,
		siteMemberId: undefined,
	}
}

const createConsoleLogger = () => ({
	phaseMark: console.log,
	appLoaded: () => console.log('appLoaded'),
	reportAppLoadStarted: console.log,
	captureError: (...args: any) => {
		console.error(...args)
	},
	setGlobalsForErrors: (/* {tags, extras} = {}*/) => {},
	breadcrumb: (/* messageContent, additionalData = {}*/) => {},
	interactionStarted: console.log,
	interactionEnded: console.log,
})

const shouldFilter = (url: string, message: string) => url && message

export function createLogger(loggerIntegrations: LoggerIntegrations): ILogger {
	const { fedopsCreator, sentryInstance, wixBiSession, viewerModel } = loggerIntegrations
	const mode = viewerModel && viewerModel.mode ? viewerModel.mode : { qa: true }
	if (mode.qa) {
		return createConsoleLogger()
	}
	const { viewerSessionId } = wixBiSession
	const biStore = getBiStore(viewerSessionId, viewerModel)
	const fedopsLogger = fedopsCreator({
		biStore,
		config: {
			phasesConfig: 'SEND_ON_START',
			appName: viewerModel.site && viewerModel.site.isResponsive ? 'thunderbolt-responsive' : 'thunderbolt',
		},
	})

	const loadInstance = () => {
		if (!process.env.browser) {
			return sentryInstance
		}
		// @ts-ignore
		sentryInstance.forceLoad()
		return sentryInstance
	}

	const phaseMark = (phase: Phase) => fedopsLogger.appLoadingPhaseStart(phase)
	if (process.env.browser) {
		window.phaseMark = phaseMark
	}

	return {
		captureError: (e, { tags, extras, groupErrorsBy = 'tags' } = {}) =>
			loadInstance().withScope((scope: any) => {
				const fingerprints = []
				for (const key in tags) {
					if (tags.hasOwnProperty(key)) {
						scope.setTag(key, tags[key])
						if (groupErrorsBy === 'tags') {
							fingerprints.push(key)
						} else if (groupErrorsBy === 'values') {
							fingerprints.push(tags[key])
						}
					}
				}

				for (const key in extras) {
					if (extras.hasOwnProperty(key)) {
						scope.setExtra(key, extras[key])
					}
				}

				if (fingerprints.length) {
					scope.setFingerprint(['{{ default }}', ...fingerprints])
				}
				sentryInstance.captureException(e)
				// @ts-ignore
				console.log(e) // Sentry capture exception swallows the error
				fedopsLogger.interactionStarted('error') // this is a workaround to get error rate until we will have support for postgresSQL in fedonomy
			}),
		setGlobalsForErrors: ({ tags, extra } = {}) =>
			sentryInstance.configureScope((scope: any) => {
				scope.addEventProcessor((event: any, hint: any) => {
					const { url } = event.request
					const { message } = hint.originalException

					if (shouldFilter(url, message)) {
						return null
					}
					if (tags) {
						event.tags = event.tags || {}
						Object.assign(event.tags, tags)
					}
					if (extra) {
						event.extra = event.extra || {}
						Object.assign(event.extra, extra)
					}
					return event
				})
			}),
		breadcrumb: (messageContent, additionalData = {}) =>
			sentryInstance.addBreadcrumb({
				message: messageContent,
				data: additionalData,
			}),
		interactionStarted: (interaction: Interaction) => fedopsLogger.interactionStarted(interaction),
		interactionEnded: (interaction: Interaction) => fedopsLogger.interactionEnded(interaction),
		phaseMark,
		reportAppLoadStarted: () => fedopsLogger.appLoadStarted(),
		appLoaded: () => fedopsLogger.appLoaded(),
	}
}

export const site = ({ logger }: Environment): ContainerModuleLoader => (bind) => {
	bind(LoggerSymbol).toConstantValue(logger)
}
