import { SiteAssetsClientAdapterFactoryImpl } from './siteAssetsClientAdapter'
import { DataFixersParams, FetchFn, SiteAssetsManifests, SiteScopeParams } from '@wix/thunderbolt-symbols'
import { RendererModel, SiteAssetsClientTopology } from '@wix/thunderbolt-ssr-api'
import { ModuleFetcherRequest, SiteAssetsMetricsReporter, SiteAssetsModuleFetcher } from 'site-assets-client'
import { SiteAssetsClientAdapter } from './types'

type ClientSiteAssetsClientAdapterFactoryParams = {
	fetchFn: FetchFn
	clientTopology: SiteAssetsClientTopology
	siteAssetsMetricsReporter: SiteAssetsMetricsReporter
	manifests: SiteAssetsManifests
	timeout: number
	dataFixersParams: DataFixersParams
	requestUrl: string
	siteScopeParams: SiteScopeParams
}

export const createClientSiteAssetsClientFactory = ({
	fetchFn,
	clientTopology,
	siteAssetsMetricsReporter,
	manifests,
	timeout,
	dataFixersParams,
	requestUrl,
	siteScopeParams,
}: ClientSiteAssetsClientAdapterFactoryParams): SiteAssetsClientAdapter => {
	const moduleFetcher: SiteAssetsModuleFetcher = {
		// TODO add real impl once fallback is supported
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetch: <T>(request: ModuleFetcherRequest): Promise<T> => {
			return Promise.resolve() as any
		},
	}

	return SiteAssetsClientAdapterFactoryImpl({
		fetchFn,
		topology: {
			...clientTopology,
			siteAssetsBaseDomain: clientTopology.siteAssetsPublicBaseDomain,
		},
		siteAssetsMetricsReporter,
		timeout,
		manifests,
		moduleFetcher,
	})({
		dataFixersParams,
		requestUrl,
		siteScopeParams,
		clientSpecMap: {} as RendererModel['clientSpecMap'],
	})
}
