import {
	HttpRequestInit,
	HttpResponse,
	MetaSiteModel,
	SiteAssetsClient,
	siteAssetsClientBuilder,
	SiteAssetsClientConfig,
	SiteAssetsCollaborators,
	SiteAssetsHttpClient,
	SiteAssetsSiteModels,
	SitePagesModel,
} from 'site-assets-client'
import { ProcessLevelSiteAssetsClientFactory, TBSiteAssetsRequest } from './types'
import { toSiteAssetsRequest } from './toSiteAssetsRequest'
import { toMetaSiteModel, toSitePagesModel } from './toSiteAssetsModel'
import { nopSiteAssetsMetricsReporter } from './nopSiteAssetsMetricsReporter'

export const SiteAssetsClientAdapterFactoryImpl: ProcessLevelSiteAssetsClientFactory = ({
	fetchFn,
	topology,
	siteAssetsMetricsReporter = nopSiteAssetsMetricsReporter(),
	timeout,
	manifests,
	moduleFetcher,
}) => ({ dataFixersParams, requestUrl, siteScopeParams, clientSpecMap }) => {
	const {
		siteAssetsPublicBaseDomain,
		siteAssetsBaseDomain,
		moduleRepoUrl,
		fileRepoUrl,
		mediaRootUrl,
		staticMediaUrl,
		pageJsonServerUrls,
	} = topology

	const config: SiteAssetsClientConfig = {
		siteAssetsTopology: {
			publicBaseDomain: siteAssetsPublicBaseDomain,
			baseDomain: siteAssetsBaseDomain,
		},
		moduleFetchTopology: {
			moduleRepoUrl,
			fileRepoUrl,
		},
		staticsTopology: {
			timeout,
			baseURLs: pageJsonServerUrls,
		},
		mediaTopology: {
			mediaRootUrl,
			staticMediaUrl,
		},
	}

	const httpClient: SiteAssetsHttpClient = {
		fetch: (url: string, init?: HttpRequestInit): Promise<HttpResponse> => {
			// TODO ** WARNING **
			//  remember to remove it once we are running with deploy preview 100%
			//  we will still need it for localhost (running locally) but not for tests
			const shouldSendSiteUrlHeader = siteAssetsBaseDomain.includes('localhost')

			const safeInit: HttpRequestInit = init || { headers: {}, method: 'GET' }

			const headersWithSiteUrl: Record<string, string> = {
				...safeInit.headers,
				siteUrl: requestUrl,
			}

			return fetchFn(url, {
				headers: shouldSendSiteUrlHeader ? headersWithSiteUrl : safeInit.headers,
				method: safeInit.method,
			})
		},
	}

	const collaborators: SiteAssetsCollaborators = {
		httpClient,
		moduleFetcher,
		metricsReporter: siteAssetsMetricsReporter,
	}

	const sitePagesModel: SitePagesModel = toSitePagesModel(dataFixersParams, siteScopeParams)
	const metaSiteModel: MetaSiteModel = toMetaSiteModel(dataFixersParams, siteScopeParams, clientSpecMap)

	const siteAssetsSiteModels: SiteAssetsSiteModels = {
		sitePagesModel,
		metaSiteModel,
	}

	const siteAssetsClient: SiteAssetsClient = siteAssetsClientBuilder(collaborators, config, siteAssetsSiteModels)

	return {
		// result() returns a (Promise of) string or json depending on the content-type of the module output
		execute(request: TBSiteAssetsRequest): Promise<string | any> {
			return siteAssetsClient
				.execute(toSiteAssetsRequest(request, manifests.node.modulesToHashes))
				.then(({ result }) => result())
		},
		calcPublicModuleUrl(request: TBSiteAssetsRequest): string {
			return siteAssetsClient.getPublicUrl(toSiteAssetsRequest(request, manifests.node.modulesToHashes))
		},
		getInitConfig() {
			return {
				siteAssetsBaseDomain: config.siteAssetsTopology.baseDomain,
			}
		},
	}
}
