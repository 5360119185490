import { ViewerModelSym, Language, SiteFeatureConfigSymbol, ViewModeSym } from '@wix/thunderbolt-symbols'
import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { Environment } from '../../types/Environment'

export const site = ({ viewerModel }: Environment): ContainerModuleLoader => (bind) => {
	const { language, viewMode, ...restOfViewerModel } = viewerModel
	bind(ViewerModelSym).toConstantValue(restOfViewerModel)
	bind(Language).toConstantValue(language)
	bind(ViewModeSym).toConstantValue(viewMode)
	Object.entries(viewerModel.siteFeaturesConfigs).forEach(([featureName, featureConfig]) =>
		bind(SiteFeatureConfigSymbol)
			.toConstantValue(featureConfig)
			.whenTargetNamed(featureName)
	)
}
