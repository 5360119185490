import { SiteAssetsMetricsReporter } from 'site-assets-client'
import { ILogger } from '@wix/thunderbolt-symbols'

export const fedopsMetricsReporter = (logger: ILogger): SiteAssetsMetricsReporter => {
	return {
		reportAsyncWithCustomKey: <T>(asyncMethod: () => Promise<T>, methodName: string, key: string): Promise<T> => {
			logger.interactionStarted(methodName, { customParam: key })
			return asyncMethod()
				.then(
					(res): Promise<T> => {
						logger.interactionEnded(methodName, { customParam: key })
						return Promise.resolve(res)
					}
				)
				.catch((error) => {
					logger.captureError(error, { tags: { methodName } })
					return Promise.reject(error)
				})
		},
		runAsyncAndReport: <T>(asyncMethod: () => Promise<T>, methodName: string): Promise<T> => {
			logger.interactionStarted(methodName)
			return asyncMethod()
				.then(
					(res): Promise<T> => {
						logger.interactionEnded(methodName)
						return Promise.resolve(res)
					}
				)
				.catch((error) => {
					logger.captureError(error, { tags: { methodName } })
					return Promise.reject(error)
				})
		},
		runAndReport: <T>(method: () => T, methodName: string): T => {
			logger.interactionStarted(methodName)
			try {
				const t = method()
				logger.interactionEnded(methodName)
				return t
			} catch (e) {
				logger.captureError(e, { tags: { methodName } })
				return e
			}
		},
		reportError: (err: Error): void => {
			logger.captureError(err)
		},
		meter: (metricName: string): void => {
			logger.interactionStarted(metricName)
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		histogram: (metricName: string, value: string): void => {},
	}
}
